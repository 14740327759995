<template>
  <div class="preview" v-if="this.simpleResult">
    <div class="nav">
      <h2>
        <span class="back" v-on:click="backToHome()"
          >&lt;&nbsp;&nbsp;返回 <span v-if="isLogin"></span
        ></span>
      </h2>
    </div>
    <!-- Payment Form -->
    <form id="order-form" method="post" hidden></form>
    <!-- End Payment Form -->
    <div class="key-vision-desktop">
      <img src="@/assets/image/love-bn-01.jpg" width="100%" />
    </div>
    <div class="key-vision-mobile">
      <img src="@/assets/image/love-bn-02.jpg" width="100%" />
    </div>
    <div class="re_container">
      <div class="re_sec d-flex flex-wrap">
        <div class="w-100 text-center py-3">
          <h3 class="heading5 product-name">测算项目：真命天子何时出现</h3>
        </div>
        <div class="w-100 border-top text-center py-3">
          <div
            class="fw-bold d-flex justify-content-center align-items-center gap-2 heading5"
          >
            <span data-name="name">{{ decodeURI(simpleResult.name) }}</span>
            <span>/</span>
            <span data-name="gender">{{
              simpleResult.gender == 1 ? '男' : '女'
            }}</span>
          </div>
          <p class="paragraph mt-3">
            阳历
            <span class="time text-preview-data">{{
              getMoment(simpleResult.birthday).year()
            }}</span>
            年
            <span class="time text-preview-data">{{
              getMoment(simpleResult.birthday).month() + 1
            }}</span>
            月
            <span class="time text-preview-data">{{
              getMoment(simpleResult.birthday).date()
            }}</span>
            日
            <!-- <% if(0 == shiChen || 23 == shiChen) {%>
            <span class="text-preview-data"><%= shiChen %>:00 - <%= shiChen %>:59</span>
            <% } else {%>
            <span class="text-preview-data"><%= shiChen %>:00 - <%= parseInt(shiChen)+1 %>:59</span>
            <% } %> -->
          </p>
          <p class="paragraph">
            农历
            <span class="text-preview-data">{{
              simpleResult.lunarBirthday.year
            }}</span>
            年
            <span class="text-preview-data">{{
              simpleResult.lunarBirthday.month
            }}</span>
            月
            <span class="text-preview-data">{{
              simpleResult.lunarBirthday.day
            }}</span>
            日
            <!-- <%= shiChenList[shiChen] %>时 -->
          </p>
          <p class="paragraph">
            <span class="text-preview-data">
              <span class="text-preview-data">{{
                getSolarHour(getMoment(simpleResult.birthday).hour())
              }}</span>
              {{ simpleResult.lunarBirthday.shiChen }} 时
            </span>
          </p>
        </div>
        <div class="w-100 border-top text-center py-5">
          <span class="paragraph original-price"> 128 元 </span>
          <span class="sale_price" data-name="salePrice"
            >限时优惠 {{ price }} 元</span
          >
          <div class="js-countdown countdown-row">
            <div class="countdown-title">优惠倒数</div>
            <div class="countdown-itspan">
              <span id="t_h"></span>：<span id="t_m"></span>：<span
                id="t_s"
              ></span>
            </div>
          </div>
        </div>
        <div class="btn btn-block btn-buy btn-payment" @click="submit()">
          付费解锁
        </div>
      </div>
      <!-- 1 -->
      <div class="re_sec">
        <div class="title_area d-flex flex-column justify-content-center gap-4">
          <div class="re_sec_img">
            <img
              v-if="simpleResult.gender == 1"
              src="@/assets/image/love-woman.png"
              alt=""
            />
            <img v-else src="@/assets/image/love-man.png" alt="" />
            <h4 class="img_title">我的真命指南</h4>
          </div>
          <p class="text-center">
            茫茫人海真命何处寻，锁定真命天子的特征，在对的方向找到对的人。
          </p>
          <button class="buy result" type="submit" @click="submit()">
            付费解锁
          </button>
        </div>
        <!-- 1-1 -->
        <div class="result_box">
          <div class="w-50 px-5">
            <h3 class="re_subtitle">伴侣特质</h3>
            <div class="d-flex gap-4">
              <p class="mx-auto">？？？？？？？？？？</p>
              <div class="blurarea-100"></div>
            </div>
          </div>
          <div class="w-50 px-5">
            <h3 class="re_subtitle">外表特征</h3>
            <div class="d-flex gap-4">
              <p class="mx-auto">？？？？？？？？？？</p>
              <div class="blurarea-100"></div>
            </div>
          </div>
          <div class="w-50 px-5">
            <h3 class="re_subtitle">人格特质</h3>
            <div class="d-flex gap-4">
              <p class="mx-auto">？？？？？？？？？？</p>
              <div class="blurarea-100"></div>
            </div>
          </div>
          <div class="w-50 px-5">
            <h3 class="re_subtitle">姓氏</h3>
            <div class="d-flex gap-4">
              <p class="mx-auto">？？？？？</p>
              <div class="blurarea-100"></div>
            </div>
          </div>
          <div class="w-50 px-5">
            <h3 class="re_subtitle">年龄差距</h3>
            <div class="d-flex gap-4">
              <p class="mx-auto">？？？？？</p>
              <div class="blurarea-100"></div>
            </div>
          </div>
          <div class="w-50 px-5">
            <h3 class="re_subtitle">真命出现年份</h3>
            <div class="d-flex gap-4">
              <p class="mx-auto">
                ？位真命天子/天女，将会在你人生的精华时期出现，其中分别是：
                <span data-name="trueLoveYear"> ？？？？？ </span>
                年他将出现在您的生命中。
              </p>
              <div class="blurarea-100"></div>
            </div>
          </div>
          <div class="w-50 px-5">
            <h3 class="re_subtitle">相似明星</h3>
            <div class="d-flex gap-4">
              <p class="mx-auto-100">？？？</p>
              <div class="blurarea"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 2 -->
      <div class="re_sec">
        <div class="title_area d-flex flex-column justify-content-center gap-4">
          <div class="re_sec_img">
            <img
              v-if="simpleResult.gender == 1"
              src="@/assets/image/love-woman.png"
              alt=""
            />
            <img v-else src="@/assets/image/love-man.png" alt="" />
            <h4 class="img_title">真命个性分析</h4>
          </div>
          <p class="text-center">
            要怎么跟我的真命好好相处呢？他会是什么个性的人呢？是温暖善良，还是聪明过人呢？
          </p>
          <button class="buy result" type="submit" @click="submit()">
            付费解锁
          </button>
        </div>
        <!-- 1-1 -->
        <div class="result_box">
          <div class="w-100 px-5">
            <h3 class="re_subtitle text-center">
              透过你的星盘发现，<br />你的对象的命盘是？？星
            </h3>
            <div class="d-flex justify-content-center">
              <img src="@/assets/image/card.png" alt="" />
            </div>
          </div>
          <div class="px-5">
            <h3 class="re_subtitle text-center">
              {{ simpleResult.xingZuo }}星性格特质详解
            </h3>
            <div class="d-flex gap-4">
              <ul
                class="mx-auto personality"
                data-name="personalityItemExplain"
              >
                <li class="intro">？？？？？？？？？</li>
                <li class="intro">？？？？？？？？？</li>
                <li class="intro">？？？？？？？？？</li>
                <li class="intro">？？？？？？？？？</li>
                <li class="intro">？？？？？？？？？</li>
              </ul>
              <div class="blurarea-100"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 3 -->
      <div class="re_sec">
        <div class="title_area d-flex flex-column justify-content-center gap-4">
          <div class="re_sec_img">
            <img src="/image/truelove/love-img03.png" alt="" />
            <h4 class="img_title">我的爱情运势</h4>
          </div>
          <p class="text-center">
            单身的你何时能顺利脱单，脱单之后又该如何好好经营一段好的姻缘，安然渡过爱情的起起落落。
          </p>
          <button class="buy result" type="submit" @click="submit()">
            付费解锁
          </button>
        </div>
        <!-- 3-1 -->
        <div class="result_box">
          <div class="w-100 border-bottom">
            <h3 class="re_subtitle text-center">婚前感情状态</h3>
            <div class="desc-item-content">
              <p class="mx-auto paragraph">？？？？？？</p>
              <div class="blurarea"></div>
            </div>
          </div>
          <div class="w-100 border-bottom">
            <h3 class="re_subtitle text-center">恋爱失败的原因</h3>
            <div class="desc-item-content">
              <p class="mx-auto paragraph">？？？？？？</p>
              <div class="blurarea"></div>
            </div>
          </div>
          <div class="w-100 border-bottom">
            <h3 class="re_subtitle text-center">婚后生活</h3>
            <div class="desc-item-content">
              <p class="mx-auto paragraph">？？？？？？</p>
              <div class="blurarea"></div>
            </div>
          </div>
          <div class="w-100 border-0">
            <h3 class="re_subtitle text-center">适合你的爱情经营秘诀</h3>
            <div class="desc-item-content">
              <p class="mx-auto paragraph">
                {{ simpleResult.loveIdea }}
              </p>
              <div class="blurarea"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 4 -->
      <div class="re_sec">
        <div class="title_area d-flex flex-column justify-content-center gap-4">
          <div class="re_sec_img">
            <img src="/image/truelove/love-img04.png" alt="" />
            <h4 class="img_title">我的爱情御守</h4>
          </div>
          <p class="text-center">
            我应该做哪些努力，让幸福到手，感情运势大吉， 守护我的美满人生。
          </p>
          <button class="buy result" type="submit" @click="submit()">
            付费解锁
          </button>
        </div>
        <!-- 4-1 -->
        <div class="result_box">
          <div class="w-100 border-bottom">
            <h3 class="re_subtitle text-center">幸福建议</h3>
            <div class="desc-item-content">
              <p class="mx-auto paragraph">
                {{ simpleResult.happySuggestion }}
              </p>
              <div class="blurarea"></div>
            </div>
          </div>
          <div class="w-100 border-0">
            <h3 class="re_subtitle text-center">给你的开运建议</h3>
            <div class="desc-item-content">
              <p class="mx-auto">
                爱情的来临需要时间跟缘份，对于感情，要更细心敏锐一些，如果单身，多留意自己周围欣赏的对象，有机会就和对方多交流，相互了解，若能情投意合，就尝把握交往看看。
              </p>
              <p class="mx-auto">
                如果你已经有交往对象，就理性相处，遇到相处问题多沟通，爱情最令人心动的就是在一起体验人生的美好，不论结果如何，都让自己在恋爱的过程中，开心幸福！
              </p>
              <div class="blurarea"></div>
            </div>
          </div>
          <!-- <div class="w-100 border-0">
            <h3 class="re_subtitle text-center">适合你的爱情经营秘诀</h3>
            <div class="desc-item-content">
              <p class="mx-auto">
                <% if(result.loveIdea.length < 60) { %>
                  <p class="paragraph"><%=result.loveIdea.slice(0, 25) %><% for(var i=0; i < result.loveIdea.slice(25).length; i++) { %>＊<% } %></p>
                <% } else { %>
                  <p class="paragraph"><%=result.loveIdea.slice(0, 45) %><% for(var i=0; i < result.loveIdea.slice(45).length; i++) { %>＊<% } %></p>
                <% } %>
              </p>
              <div class="blurarea"></div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import moment from 'moment-timezone'

import { mapState } from 'vuex'
import { Base64 } from 'js-base64'
import { SHI_CHEN } from '@/constants/shi-chen-list'

import share from '@/share'

export default {
  name: 'PreviewView',
  data() {
    return { simpleResult: null, activeLeft: true }
  },
  beforeRouteEnter(to, from, next) {
    if (Object.entries(store.state.fortune.request).length === 0) {
      next({ name: 'Home' })
    } else {
      next()
    }
  },
  mounted: async function () {
    localStorage.setItem(
      'previewRequest',
      JSON.stringify(this.$store.state.fortune.request)
    )
    document.documentElement.scrollTop = 0

    this.$store.dispatch('fortune/getSimpleResult').then(
      (res) => {
        countdown()
        this.simpleResult = res
      },
      (err) => {
        alert('对不起,无法辨识')
        console.log(err)
        this.$router.push({ name: 'Home' })
      }
    )

    if (localStorage.getItem('stage') === 'wxLogin') {
      localStorage.removeItem('stage')
      let payload = {}
      payload.ttxid = this.$store.state.user.ttxid
      payload.timestamp = this.$store.state.user.timestamp
      payload.token = this.$store.state.user.token

      if (share.isWeixin()) {
        let chargeResp = await axios.post(
          'https://api.taotaoxi.cn/payment/wechat/v2/charge?ttxid=' +
            payload.ttxid +
            '&timestamp=' +
            payload.timestamp +
            '&token=' +
            payload.token,
          {
            productId: this.$store.state.fortune.data.weChatId,
            appId: 'wxa2acc77c8368a9dc',
            subject: '真命天子何时出现',
            quizData: JSON.stringify(this.$store.state.fortune.request),
            method: 'pub',
          }
        )
        payload.orderNo = chargeResp.data.orderNo

        // jump to wx jsapi payment
        let successUrl = Base64.encodeURI(
          location.protocol +
            '//' +
            location.host +
            '/result/' +
            chargeResp.data.orderNo +
            '?channel=' +
            this.channel
        )
        let failUrl = Base64.encodeURI(
          location.href + '?channel=' + this.channel
        )
        let charge = Base64.encodeURI(
          unescape(encodeURIComponent(JSON.stringify(chargeResp.data)))
        )
        location.replace(
          'https://shared-resource.taotaoxi.net/wx-pub-pay/?charge=' +
            charge +
            '&success_next=' +
            successUrl +
            '&fail_next=' +
            failUrl
        )
      }
    }
  },
  methods: {
    backToHome: function () {
      this.$router.push({ name: 'Home' })
    },
    linkToService: function () {
      this.$router.push({ name: 'Service' })
    },
    submit: async function () {
      this.$store.commit('fortune/addLoading')
      if (!store.getters.isLogin) {
        localStorage.setItem('stage', 'wxLogin')
        let res = await store.dispatch('login', {
          channel: this.$store.state.fortune.channel,
        })
        if (!res) {
          return
        }
      }

      let payload = {}
      payload.ttxid = this.$store.state.user.ttxid
      payload.timestamp = this.$store.state.user.timestamp
      payload.token = this.$store.state.user.token

      try {
        if (share.isWeixin()) {
          let chargeResp = await axios.post(
            'https://api.taotaoxi.cn/payment/wechat/v2/charge?ttxid=' +
              payload.ttxid +
              '&timestamp=' +
              payload.timestamp +
              '&token=' +
              payload.token,
            {
              productId: this.$store.state.fortune.data.weChatId,
              appId: 'wxa2acc77c8368a9dc',
              subject: '真命天子何时出现',
              quizData: JSON.stringify(this.$store.state.fortune.request),
              method: 'pub',
            }
          )
          payload.orderNo = chargeResp.data.orderNo

          // jump to wx jsapi payment
          let successUrl = Base64.encodeURI(
            location.protocol +
              '//' +
              location.host +
              '/result/' +
              chargeResp.data.orderNo +
              '?channel=' +
              this.channel
          )
          let failUrl = Base64.encodeURI(
            location.protocol +
              '//' +
              location.host +
              '/preview' +
              '?channel=' +
              this.channel
          )
          let charge = Base64.encodeURI(
            unescape(encodeURIComponent(JSON.stringify(chargeResp.data)))
          )
          location.replace(
            'https://shared-resource.taotaoxi.net/wx-pub-pay/?charge=' +
              charge +
              '&success_next=' +
              successUrl +
              '&fail_next=' +
              failUrl
          )
        } else {
          let chargeResp = await axios.post(
            'https://api.taotaoxi.cn/payment/wechat/v2/charge?ttxid=' +
              payload.ttxid +
              '&timestamp=' +
              payload.timestamp +
              '&token=' +
              payload.token,
            {
              productId: this.$store.state.fortune.data.weChatH5Id,
              appId: 'wxa2acc77c8368a9dc',
              subject: '真命天子何时出现',
              quizData: JSON.stringify(this.$store.state.fortune.request),
              method: 'wap',
            }
          )

          payload.orderNo = chargeResp.data.orderNo
          // jump to wx h5 payment
          let redirectUrl =
            'https://' +
            location.host +
            '/result/' +
            chargeResp.data.orderNo +
            '?channel=' +
            this.channel
          location.replace(
            chargeResp.data.link +
              '&redirect_url=' +
              encodeURIComponent(redirectUrl)
          )
        }
      } catch (err) {
      } finally {
        this.$store.commit('fortune/minusLoading')
      }
    },
    getMoment: function (timeString) {
      return moment.tz(timeString, 'YYYYMMDDHH', 'Etc/GMT-8')
    },
    getSolarHour: function (hour) {
      let str
      if (0 === hour || 23 === hour) {
        str = hour + ':00 ~ ' + hour + ':59'
      } else {
        str = hour + ':00 ~ ' + (hour + 1) + ':59'
      }
      return str
    },
    getLunarHour: function (hour) {
      if (hour !== 0 && hour % 2 === 0) {
        hour--
      }
      return SHI_CHEN[hour]
    },
    monthlyId: function (month) {
      return 'accordion' + month
    },
    toggleTag: function () {
      this.activeLeft = !this.activeLeft
    },
  },
  computed: {
    ...mapState('fortune', {
      channel: 'channel',
      count: 'count',
      price: function (state) {
        if (share.isWeixin()) {
          return state.data.price
        } else {
          return state.data.priceH5
        }
      },
      previewRequest: function (state) {
        return state.data.request
      },
    }),
    ...mapState({
      isLogin: (state) => state.login,
    }),
  },
}

let countdown = function () {
  let EndTime = new Date().getTime() + 7200000

  function GetRTime() {
    var NowTime = new Date()
    var t = EndTime - NowTime.getTime()
    var h = 0,
      m = 0,
      s = 0
    if (t >= 0) {
      h = Math.floor((t / 1000 / 60 / 60) % 24)
      m = Math.floor((t / 1000 / 60) % 60)
      s = Math.floor((t / 1000) % 60)
    }
    try {
      document.getElementById('t_h').innerHTML = h
      document.getElementById('t_m').innerHTML = m
      document.getElementById('t_s').innerHTML = s
    } catch (ex) {
      clearInterval(GetRTimeInter)
    }
  }

  let GetRTimeInter = setInterval(GetRTime, 200)
}
</script>

<!-- <style src="@/assets/css/2022style.css" scoped></style> -->
<style scoped>
h2 {
  margin: 0;
}
.nav {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
}
.nav h2 {
  line-height: inherit;
  font-size: 1.5em;
  font-weight: bold;
}
.back {
  position: absolute;
  left: 3%;
  cursor: pointer;
}

.preview {
  background-color: #f3efed;
}
.key-vision-desktop {
  display: block;
}
.key-vision-mobile {
  display: none;
}
.original-price {
  text-decoration: line-through;
  padding-right: 10px;
}
.re_container {
  padding-top: 0;
}

.heading5 {
  font-size: 21px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0;
}
.paragraph {
  font-size: 16px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: 0;
}

.text-preview-data {
  color: #00b2ee;
}
.btn {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 16px;
  border-radius: 8px;
}
.btn-buy {
  color: #fff;
  background-color: #ef3900;
  border-color: #ef3900;
}
.btn.btn-block {
  display: block;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .key-vision-desktop {
    display: none;
  }
  .key-vision-mobile {
    display: block;
  }
  .heading1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0;
  }
}

@media (max-width: 576px) {
  .heading5 {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0;
  }
}
</style>
